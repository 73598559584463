html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

body {
  color: rgb(50, 49, 48);
  background: rgb(255, 255, 255);
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  font-weight: 400;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
}

ul.no-bullets {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}

.header {
  display: block;
  background-image: url("./img/acs.png");
  background-size: 150px;
  background-repeat: no-repeat;
  background-position: right;
}

.App {
  margin-left: 3em;
  margin-right: 3em;
}

#testPane {
  margin-top: 1em;
}
.separator-light {
  border-top: 1px solid #d1d1d1;
  margin-top: 1em;
  margin-bottom: 1em;
}
.separator-dark {
  border-top: 1px solid #201f1e;
  margin-top: 1em;
  margin-bottom: 1em;
}
.ms-Grid .ms-Grid-col {
  padding-left: 6px;
  padding-right: 6px;
}
.ms-Grid .ms-Grid-row.inactive {
  color: #979593;
}
.ms-Grid .ms-Grid-row.active {
  color: #201f1e;
  font-weight: bold;
}
.test-status {
  display: inline-block;
  margin-left: 6px;
}
.ms-Grid .ms-Grid-col.pending {
  color: #a7a7a7;
}
.ms-Grid .ms-Grid-col.running {
  color: #44baff;
}
.ms-Grid .ms-Grid-col.skipped {
  color: #ffaa44;
}

.captcha-btn-left {
  padding-right: 5px;
}

.captcha-btn-right {
  padding-left: 5px;
}

.captcha-img {
  width: 100%;
}

.captcha-input {
  width: 100%;
}

.captcha-area {
  margin-bottom: 5px;
}

.results-button {
  border: none;
  background: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  float: right;
}

.primary-text {
  padding-bottom: 12px;
}

.secondary-text {
  font-size: 12px;
  color: #605E5C;
}

.collapsed {
  display: none;
}

.underlined {
  text-decoration-line: underline;
}

.ms-ProgressIndicator-itemProgress {
  width: auto; /* Prevent width getting overriden by fabric.components.min.css */
}

#progressBar {
  padding-left: 6px;
}

#testIdDisplay {
  padding-left: 6px;
}

.statusIcon {
  position: relative;
  top: 2px;
}
.statusIcon.pass {
  color: #2fc32f;
}

.statusIcon.fail {
  color: #a4262c;
}

.testResults {
  padding-left: 0; 
  padding-right: 0;
}

.errorMessageBar {
  margin-bottom: 5px;
}